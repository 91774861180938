export const svg = (
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1127_5439)">
      <path
        d="M154.814 141.185C179.769 110.912 175.458 66.1408 145.185 41.1855C114.912 16.2302 70.1406 
            20.5412 45.1853 50.8144C20.23 81.0875 24.541 125.859 54.8142 150.814C85.0874 175.77 129.859 171.459
             154.814 141.185Z"
        fill="#E6EFE1"
      />
      <path
        d="M96.2826 149.09C96.3257 149.564 96.7043 150.618 98.1079 152.215C99.5116 153.813 102.94 
            155.213 109.673 153.862C116.941 152.419 138.704 147.386 138.704 
            147.386C138.704 147.386 143.153 147.244 146.731 138.847C150.304 130.436 178.536 66.5203 178.536 
            66.5203C178.536 66.5203 180.548 63.2947 179.381 60.4188C177.492 55.7904 177.141 54.4389 175.147 
            55.0232C169.904 56.558 141.721 65.1891 140.004 65.7318C138.286 66.2745 136.941 66.8368 135.939 
            68.8747C131.924 77.0902 96.2358 148.557 96.2921 149.072L96.2826 149.09Z"
        fill="#76BC58"
      />
      <path
        d="M98.6393 153.556C100.244 156.081 101.361 156.094 103.529 155.82C110.65 154.926 136.544 
            149.562 138.521 149.165C140.498 148.769 144.058 148.513 145.738 144.524C149.278 136.108 179.983 
            65.165 180.178 64.7225C180.558 63.9015 180.695 63.0813 180.099 61.5329C179.437 59.8227 178.544 
            57.043 177.927 55.9124C177.41 54.9487 176.42 54.7586 175.475 54.9505C176.832 54.8569 177.191 
            55.9927 176.934 56.5767C176.158 58.3149 143.854 132.825 140.944 139.381C139.982 141.544 138.905 
            142.234 136.151 142.968C133.498 143.684 104.654 149.112 98.0598 150.083C96.4436 150.32 96.2984 
            149.619 96.2516 149.086C96.1789 149.916 97.6927 152.103 98.6116 153.565L98.6393 153.556Z"
        fill="#398717"
      />
      <path
        d="M150.179 66.6909C150.368 66.9512 150.089 67.4659 149.555 67.848C149.021 68.2301 148.44 
            68.3221 148.251 68.0619C148.062 67.8016 148.342 67.2869 148.876 66.9048C149.41 66.5226 149.991 
            66.4307 150.179 66.6909Z"
        fill="#398717"
      />
      <path
        d="M159.339 64.9693C159.699 64.8555 160.133 64.3983 160.301 63.9645C160.482 63.5263 160.335 
            63.2529 159.975 63.3667L152.348 65.7462L152.334 65.7506C151.974 65.8644 151.54 66.3216 151.372 
            66.7554C151.191 67.1936 151.338 67.467 151.698 67.3532L159.325 64.9737L159.339 64.9693Z"
        fill="#398717"
      />
      <path
        d="M121.859 137.704C122.7 138.871 121.803 140.952 119.875 142.323C117.938 143.712 115.689 143.874 
            114.862 142.703C114.021 141.537 114.917 139.456 116.845 138.085C118.783 136.696 121.032 136.534 121.859 
            137.704Z"
        fill="#398717"
      />
      <path
        d="M102.803 138.727L143 129.453L171.389 65.5492L134.084 76.1641L102.803 138.727Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M147.684 72.2932L141.855 73.9521L150.445 112.705L154.683 103.17L147.684 72.2932Z"
        fill="#A7A7A7"
      />
      <path
        opacity="0.15"
        d="M147.612 119.069L139.3 74.6838L134.085 76.164L128.327 87.6875L135.703 131.135L143.002
             129.453L147.612 119.069Z"
        fill="#A7A7A7"
      />
      <path
        d="M83.0968 119.425C70.8393 115.673 59.0626 103.303 48.0952 82.6585C31.7302 51.8544 34.6949 35.2988 
            40.0552 26.8308C41.4819 24.608 43.6841 23.0433 46.2464 22.43L54.6493 20.3345C55.5018 20.1206 56.4014 20.1517 
            57.2326 20.4062C58.7958 20.8847 59.9995 22.0944 60.4567 23.6591L66.5846 44.1108C67.2969 46.527 66.011 49.1321 
            63.657 50.0398L55.7935 53.0331C54.6303 53.464 53.9472 54.7204 54.2113 55.941L54.4313 57.0396C55.1826 60.7025 
            56.124 65.2513 61.5608 75.5048C67.0101 85.762 70.2563 89.076 72.871 91.7489L73.6544 92.5586C73.9534 92.8672 
            74.32 93.088 74.7294 93.2133C75.4738 93.4412 76.3035 93.3017 76.9449 92.8467L83.8395 88.0099C85.0821 87.142 
            86.6195 86.907 88.0586 87.3475C88.9767 87.6286 89.7778 88.1588 90.3913 88.9029L103.896 105.425C105.546 107.437
             105.327 110.368 103.384 112.107L96.9436 117.897C94.9973 119.69 92.468 120.639 89.8274 120.577C87.5723 120.497 
             85.3386 120.125 83.1923 119.468L83.0806 119.434L83.0968 119.425Z"
        fill="#76BC58"
      />
      <path
        d="M63.6666 50.0561C66.0205 49.1484 67.3064 46.5433 66.5942 44.1271L60.4663 23.6754C60.0052 22.1231 58.7929
             20.8972 57.2421 20.4225C56.3985 20.1642 55.5113 20.1369 54.6588 20.3508L46.256 22.4463L55.8192 53.0408L63.6828 
             50.0475L63.6666 50.0561Z"
        fill="#95CC81"
      />
      <path
        d="M103.895 105.425L90.3904 88.9028C89.7769 88.1587 88.9758 87.6285 88.0577 87.3474C86.6062 86.9031 85.0812
             87.1419 83.8386 88.0098L76.944 92.8466L96.9465 117.885L103.387 112.095C105.318 110.352 105.549 107.424 103.899 
             105.413L103.895 105.425Z"
        fill="#95CC81"
      />
      <g opacity="0.29">
        <path
          d="M54.4432 57.0435L54.2232 55.9449C53.9591 54.7243 54.6384 53.4804 55.8053 53.037L63.6689 50.0437C66.0228
                 49.136 67.3088 46.5309 66.5965 44.1147L66.1853 42.7541C65.5377 44.1163 64.5677 45.5562 63.0283 46.374C60.4032 
                 47.7686 58.9512 48.301 56.3522 49.9207C53.1995 51.8865 54.0844 55.6446 54.5113 57.3086C54.4969 57.2228 54.4739
                  57.1207 54.4594 57.0349L54.4432 57.0435Z"
          fill="#398717"
        />
      </g>
      <g opacity="0.29">
        <path
          d="M103.896 105.425L102.355 103.543C101.412 105.737 100.061 108.2 98.1432 110.476C95.9301 113.096 91.0499 
                117.07 84.1104 115.272C77.1671 113.485 60.2319 99.97 49.2607 79.3383C36.4941 55.3076 35.4951 39.9539 38.2085 
                30.3364C38.1267 30.5149 38.0573 30.6972 37.988 30.8795C37.9462 31.0159 37.8796 31.1448 37.8216 31.2899C37.7523 
                31.4722 37.6867 31.6421 37.6297 31.8282C37.5803 31.9895 37.5224 32.1345 37.4606 32.292C37.4036 32.4781 37.3467 
                32.6642 37.2897 32.8503C37.2403 33.0116 37.1909 33.1729 37.1378 33.3466C37.0808 33.5327 37.0238 33.7188 36.9631 
                33.9173C36.9099 34.091 36.8691 34.2684 36.8284 34.4459C36.7714 34.632 36.7355 34.8381 36.6909 35.028C36.6463 
                35.2179 36.6018 35.4078 36.5658 35.6139C36.5213 35.8038 36.4891 35.9975 36.4408 36.1998C36.401 36.4183 36.3575 
                36.6492 36.3177 36.8677C36.2894 37.049 36.2486 37.2265 36.2164 37.4202C36.1483 37.82 36.105 38.2273 36.0456 
                38.6433C36.0172 38.8246 36.005 38.9973 35.9891 39.1824C35.9628 39.4457 35.9364 39.709 35.9101 39.9723C35.8904 
                40.1698 35.8792 40.3835 35.8719 40.5848C35.8618 40.8395 35.8354 41.1028 35.8253 41.3575C35.8142 41.5712 35.8154 
                41.7887 35.8043 42.0024C35.7904 42.2695 35.7926 42.528 35.7911 42.7989C35.7924 43.0164 35.7899 43.2463 35.7873 
                43.4762C35.7858 43.7471 35.7929 44.0343 35.8038 44.309C35.8137 44.5427 35.8149 44.7602 35.8248 44.9939C35.8405 
                45.2972 35.8562 45.6005 35.8719 45.9038C35.8856 46.1251 35.8993 46.3464 35.9129 46.5677C35.9421 46.9158 35.9674 
                47.2764 36.0051 47.6407C36.0302 47.8248 36.039 48.0175 36.0517 48.1977C36.1145 48.7461 36.1735 49.307 36.2573 
                49.8754C36.2814 50.0184 36.3016 50.1739 36.3257 50.3169C36.3816 50.7547 36.4538 51.1839 36.5308 51.6416C36.5693 
                51.8705 36.6115 52.087 36.65 52.3159C36.725 52.6916 36.7837 53.076 36.8673 53.4679C36.9144 53.713 36.9701 53.9743 
                37.0296 54.2232C37.1045 54.5989 37.1919 54.9784 37.2792 55.358C37.3349
                 55.6193 37.3993 55.8968 37.4674 56.1618C37.5547 
                56.5414 37.6545 56.9247 37.7504 57.3204C37.8186 57.5855 37.8953 
                57.8668 37.9596 58.1443C38.0642 58.5563 38.1849 58.9596 
                38.3019 59.3753C38.3824 59.6442 38.4505 59.9093 38.531
                 60.1782C38.6701 60.655 38.8215 61.1355 38.9729 61.6161C39.04 
                61.8401 39.1109 62.0518 39.178 62.2759C39.4089 62.9843 39.6484 
                63.7089 39.9003 64.4374C39.9272 64.527 39.9664 64.6204 
                0.0057 64.7139C40.2308 65.3527 40.4645 66.0077 40.7106 
                66.6665C40.8073 66.9267 40.9164 67.1908 41.0131 67.4511C41.2104 
                67.9592 41.399 68.4512 41.6087 68.9631C41.7312 69.272 
                41.8538 69.5809 41.9925 69.8812C42.1973 70.3645 42.4022 70.8479 
                42.6032 71.3436C42.7468 71.6725 42.8779 71.9976 43.0339 
                72.3303C43.2511 72.8174 43.4646 73.317 43.6904 73.8203C43.8464 
                74.153 43.99 74.4819 44.1421 74.827C44.3766 75.3466 44.6235 
                75.8699 44.8827 76.397C45.0387 76.7297 45.1985 77.05 45.3544 
                77.3827C45.6395 77.9585 45.9332 78.5504 46.2269 79.1424C46.3695 
                79.4303 46.512 79.7181 46.6546 80.006C47.1033
                 80.8896 47.5643 81.7771 48.0426 82.6969C51.4688 89.1462 54.9779 
                 94.793 58.5486 99.6176C59.9794 101.548 61.4234 103.347
                 62.8681 105.01C69.4028 112.52 76.1463 117.352 83.0443 119.464L83.1559 
                 119.498C83.6894 119.661 84.2391 119.816 84.7839
                 119.942C86.431 120.324 88.1111 120.553 89.791 120.606C90.4521 
                 120.619 91.1036 120.574 91.7454 120.472C92.0601 120.419 92.3786
                 120.354 92.6923 120.26C93.3122 120.097 93.9137 119.86 94.5019 
                 119.579C94.7898 119.436 95.0691 119.278 95.3521 119.106C95.902
                  118.773 96.4299 118.378 96.9072 117.927L103.347 112.137C103.608 
                  111.905 104.11 111.109 104.699 110.027C105.191 108.508 
                 104.931 106.787 103.847 105.451L103.896 105.425Z"
          fill="#398717"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1127_5439">
        <rect width="200" height="200" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
