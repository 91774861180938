import React from 'react';
import { Flex } from '@clariness/flex';
import { Notification } from '@clariness/notification';
import { Store } from 'store';

export const Notifications: React.FC = () => {
  const { notifications, removeNotification } = Store.useNotifications();

  return (
    <Flex
      sx={{
        position: 'fixed',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '20px',
        pb: '70px',
        pointerEvents: 'none',
        zIndex: 1000,

        '& > *': {
          pointerEvents: 'auto',
        },
      }}
    >
      {notifications.map((notification) => (
        <Notification
          sx={{ fontFamily: 'Noto Sans,sans-serif' }}
          key={notification.id}
          id={notification.id}
          description={notification.description}
          variant={notification.variant}
          onClose={() => removeNotification(notification.id)}
          autoRemoveDelay={notification.autoRemoveDelay}
          dismissible={notification.dismissible}
        />
      ))}
    </Flex>
  );
};
