import React from 'react';
import { Icon, IconProps } from '@clariness/icon';
import { Box } from 'theme-ui';

type BellIconProps = IconProps & {
  counter?: number;
};
export const Bell = ({ size = 20, counter = 0, ...props }: BellIconProps) => {
  return (
    <Icon sx={{ position: 'relative' }} ml={1} {...props}>
      <svg
        width={size}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.3038 17C11.1573 17.2525 10.947 17.4622 10.694 17.6079C10.441 17.7536 10.1541 17.8303 9.86214 17.8303C9.57016 17.8303 9.2833 17.7536 9.03029 17.6079C8.77727 17.4622 8.56698 17.2525 8.42048 17M18.1955 13.6666H1.52881C2.19185 13.6666 2.82773 13.4032 3.29658 12.9344C3.76542 12.4656 4.02881 11.8297 4.02881 11.1666V6.99996C4.02881 5.45286 4.64339 3.96913 5.73735 2.87517C6.83131 1.78121 8.31505 1.16663 9.86214 1.16663C11.4092 1.16663 12.893 1.78121 13.9869 2.87517C15.0809 3.96913 15.6955 5.45286 15.6955 6.99996V11.1666C15.6955 11.8297 15.9589 12.4656 16.4277 12.9344C16.8965 13.4032 17.5324 13.6666 18.1955 13.6666Z"
          stroke="#535A5F"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {counter > 0 && (
        <Box
          sx={{
            backgroundColor: 'red-alert',
            color: 'text-inverted',
            width: '12px',
            height: '12px',
            borderRadius: '50%',
            position: 'absolute',
            right: '-3px',
            top: '-5px',
            fontSize: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {counter}
        </Box>
      )}
    </Icon>
  );
};
