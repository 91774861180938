import { Modal } from '@clariness/modal';
import { Icon } from '@clariness/icon';
import { Store } from 'store';
import * as ReactIntl from 'react-intl';
import { Text } from '@clariness/text';
import { Button } from '@clariness/button';
import { FormattedMessage } from 'react-intl';
import { Flex } from '@clariness/flex';
import { captureEventV2 } from 'utils/events/captureEvent';
import { Events } from '@clariness/tracking-event-runtime';
import { svg } from './svg';

interface Props {
  investigatorID: string;
}

export const RecontactedPatientModal = ({ investigatorID }: Props) => {
  const { closeModal } = Store.useModal();

  const intl = ReactIntl.useIntl();

  const handleCloseModal = (goToTheListButtonClicked: boolean) => {
    closeModal();
    captureEventV2(
      Events.InvestigatorService.InvestigatorViewedRecontactingModal({
        InvestigatorID: investigatorID,
        GoToTheListButtonClicked: goToTheListButtonClicked,
      })
    );
  };

  return (
    <Modal
      sx={{
        width: '700px',
      }}
      onClose={closeModal}
    >
      <Modal.Header
        title={intl.formatMessage({
          id: 'is.recontacted_patient_modal.title',
          defaultMessage: 'Patients recontacted by ClinLife',
        })}
        sx={{
          height: '74px',
        }}
      />
      <Modal.Body>
        <Flex
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            gap: 10,
          }}
        >
          <Icon>{svg}</Icon>

          <Text
            variant="p"
            sx={{
              margin: '20px',
            }}
          >
            <FormattedMessage
              id="is.recontacted_patient_modal.description"
              defaultMessage="Some of the patients were recontacted at your site 
            and are still interested in participating in the study.
             These are marked at the top of your patient list."
            />
            <br />
          </Text>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Flex
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button variant="secondary" onClick={() => handleCloseModal(false)}>
            <FormattedMessage
              id="is.recontacted_patient_modal.skip.button"
              defaultMessage="Skip"
            />
          </Button>

          <Button
            sx={{
              marginLeft: '24px',
            }}
            onClick={() => handleCloseModal(true)}
          >
            <FormattedMessage
              id="is.recontacted_patient_modal.go_to_the_list.button"
              defaultMessage="Go to the list"
            />
          </Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};
