import { Flex } from '@clariness/flex';
import { Text } from '@clariness/text';
import { useIntl } from 'react-intl';
import { LabeledContent } from 'components/content';
import { QuestionDto, ReferralDto, ReScreenerQuestionDto } from 'common/api';
import * as _ from 'lodash-es';
import { sortBy } from 'lodash-es';
import { Tooltip } from '@clariness/tooltip';
import {
  getOfflineScreenerAnswer,
  getRelevantOfflineScreener,
  hasBsaScoreQuestions,
  mapBSAOnlineQuestionToScore,
  mapBSAOfflineQuestionToScore,
} from 'common/question/question';
import { Info } from 'components/icons/Info/Info';
import { getAnswerValue } from 'common/answer/answer-value';
import { calculateBMIScore } from 'common/answer/calculate-bmi';
import { Image } from '@clariness/image';
import { Box } from '@clariness/box';
import { Services } from 'services';
import { BoxLoader } from 'components/loaders';

type PatientDetailsScreenerProps = {
  referralId?: string;
};

function mapOfflineQuestionToAnswer(
  question: QuestionDto | ReScreenerQuestionDto,
  referral?: ReferralDto
) {
  if (undefined === referral) {
    return {};
  }
  const relevantOfflineScreener = getRelevantOfflineScreener(referral);
  const answers = getOfflineScreenerAnswer(relevantOfflineScreener, question);

  return {
    id: question.id,
    question: question.question,
    questionType: question.type,
    visibleToInvestigators: question.visibleToInvestigators,
    answers: answers?.values
      ? _.reject(
          answers?.values.map((answer) => getAnswerValue(question, answer)),
          _.isNil
        )
      : [],
    answerValues: answers,
  };
}

function mapOnlineQuestionToAnswer(
  question: QuestionDto,
  referral?: ReferralDto
) {
  if (undefined === referral) {
    return {};
  }
  const answers = referral.patientScreener.answers?.find(
    (answer) => answer.questionId === question.id
  );

  return {
    id: question.id,
    question: question.question,
    questionType: question.type,
    visibleToInvestigators: question.visibleToInvestigators,
    answers: answers?.values
      ? _.reject(
          answers?.values.map((answer) => {
            const questionAnswerChoice = question.choices?.find(
              (choice) => choice.id === answer.questionChoiceId
            );
            return {
              value: getAnswerValue(question, answer),
              imagePublicUrl: questionAnswerChoice?.mediaAsset?.publicUrl,
              imageAltText: questionAnswerChoice?.altText,
            };
          }),
          _.isNil
        )
      : [],
    answerValues: answers,
    mediaAsset: {
      publicUrl: question?.mediaAsset?.publicUrl,
      altText: question?.mediaAsset?.altText,
    },
  };
}

function createLabeledContent(
  screenerQuestionsAndAnswer: Array<any>,
  bsaScoreTotal: Array<any>,
  intl: any,
  preScreenerAnswersTranslationId: string,
  preScreenerAnswersTranslationDefault: string
) {
  if (screenerQuestionsAndAnswer.length === 0) {
    return <div />;
  }

  return (
    <LabeledContent
      label={intl.formatMessage({
        id: preScreenerAnswersTranslationId,
        defaultMessage: preScreenerAnswersTranslationDefault,
      })}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          gap: '12px',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            gap: '20px',
            pb: '20px',
          }}
        >
          {screenerQuestionsAndAnswer
            .filter(
              (screenerQuestion) => screenerQuestion.visibleToInvestigators
            )
            .map((screenerQuestion, index) => (
              <Flex
                key={screenerQuestion.id}
                sx={{
                  flexDirection: 'column',
                  gap: '5px',
                  mb: '16px',
                }}
              >
                <Flex gap={2}>
                  <Text variant="pBold">{`${index + 1}. `}</Text>
                  <Text
                    variant="p"
                    sx={{
                      '& > p, ul': {
                        m: 0,
                      },
                      fontWeight: '400',
                    }}
                    dangerouslySetInnerHTML={{
                      __html: screenerQuestion.question,
                    }}
                  />
                </Flex>
                {screenerQuestion?.mediaAsset?.publicUrl && (
                  <Image
                    src={screenerQuestion.mediaAsset.publicUrl}
                    alt={screenerQuestion.mediaAsset.altText}
                    sx={{
                      maxHeight: '300px',
                      maxWidth: '300px',
                      borderRadius: '5px',
                    }}
                  />
                )}
                {screenerQuestion.answers.map((answer: any) => {
                  return (
                    <>
                      <Box
                        sx={{
                          fontWeight: '400',
                          fontSize: '0.875rem',
                        }}
                        dangerouslySetInnerHTML={{
                          __html: answer?.value ? answer.value : answer,
                        }}
                      />
                      {answer?.imagePublicUrl && (
                        <Image
                          src={answer.imagePublicUrl}
                          alt={answer.imageAltText}
                          sx={{
                            maxHeight: '160px',
                            maxWidth: '160px',
                            borderRadius: '5px',
                            mb: '5px',
                          }}
                        />
                      )}
                    </>
                  );
                })}
                {screenerQuestion.questionType === QuestionDto.type.BMI && (
                  <Text
                    variant="p"
                    sx={{
                      '& > p, ul': {
                        m: 0,
                      },
                      fontWeight: '400',
                      fontSize: '0.875rem',
                    }}
                  >
                    BMI : {calculateBMIScore(screenerQuestion.answerValues)}
                  </Text>
                )}
              </Flex>
            ))}

          {bsaScoreTotal.map((bst) => (
            <Flex
              sx={{
                flexDirection: 'column',
                gap: '5px',
              }}
            >
              <Tooltip
                position="top right"
                trigger={
                  <Flex
                    sx={{
                      alignItems: 'center',
                      gap: '5px',
                    }}
                  >
                    <Text variant="small">{bst.label}</Text>
                    <Info size={18} color="var(--theme-ui-colors-text1)" />
                  </Flex>
                }
              >
                <Text variant="small" sx={{ color: 'white' }}>
                  This is the total amount of BSA values entered and equates to
                  the % of the BSA across the patient’s body.
                </Text>
              </Tooltip>
              <Text variant="p">{bst.score}</Text>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </LabeledContent>
  );
}
function getOfflineOrReScreenerQuestionsSorted(
  referral: ReferralDto | undefined
) {
  if (referral?.reScreener) {
    return sortBy(referral.reScreener.reScreenerQuestions, ['order']);
  }
  return sortBy(referral?.screener.questions, ['order']).filter((question) => {
    return question.isOffline;
  });
}

export const PatientDetailsScreener = ({
  referralId,
}: PatientDetailsScreenerProps) => {
  const intl = useIntl();
  const { screener, isLoading } = Services.Referrals.useGetScreener(referralId);

  const onlineScreenerQuestions = sortBy(screener?.screener.questions, ['order'])
    .filter(question => {
      return !question.isOffline;
    });
  const offlineScreenerQuestions = getOfflineOrReScreenerQuestionsSorted(screener);

  const screenerOnlineQuestions = onlineScreenerQuestions
    .map(question => mapOnlineQuestionToAnswer(question, screener)) || [];
  const screenerOfflineQuestions = offlineScreenerQuestions
    .map(question => mapOfflineQuestionToAnswer(question, screener)) || [];

  const totalBsaScoreLabel = 'Total BSA Score';
  const onlineBsaTotalScore = [];
  if (hasBsaScoreQuestions(onlineScreenerQuestions)) {
    const totalOnlineBSAScore = onlineScreenerQuestions
      .map(question => mapBSAOnlineQuestionToScore(question, screener))
      .reduce((partialSum, a) => partialSum + a, 0);

    onlineBsaTotalScore.push({
      label: totalBsaScoreLabel,
      score: [totalOnlineBSAScore.toString()],
    });
  }

  const offlineBsaTotalScore = [];
  if (hasBsaScoreQuestions(offlineScreenerQuestions)) {
    const totalOfflineBSAScore = offlineScreenerQuestions
      .map(question => mapBSAOfflineQuestionToScore(question, screener))
      .reduce((partialSum, a) => partialSum + a, 0);

    offlineBsaTotalScore.push({
      label: totalBsaScoreLabel,
      score: [totalOfflineBSAScore.toString()],
    });
  }

  return isLoading ? (
    <BoxLoader />
  ) : (
    <Flex sx={{ flexDirection: 'row', gap: '48px' }}>
      <Flex
        sx={{
          width: '50%',
        }}
      >
        {createLabeledContent(
          screenerOnlineQuestions,
          onlineBsaTotalScore,
          intl,
          'is.patient_details_modal.online_screening_title',
          'Online Screening'
        )}
      </Flex>
      <Flex
        sx={{
          width: '50%',
        }}
      >
        {createLabeledContent(
          screenerOfflineQuestions,
          offlineBsaTotalScore,
          intl,
          'prescreen_offline_answers_content',
          'Offline Screening'
        )}
      </Flex>
    </Flex>
  );
};
