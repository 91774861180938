import React, { useState } from 'react';
import { AccountPageLayout } from 'pages/Account/components';
import { FormattedMessage } from 'react-intl';
import { Flex } from '@clariness/flex';
import { Box } from '@clariness/box';
import { Checkbox } from '@clariness/checkbox';
import { LabeledCheckbox } from '@clariness/labeled-checkbox';
import { Text } from '@clariness/text';
import { Button } from '@clariness/button';
import { ArrowIcon } from '@clariness/icon';
import { Grid } from 'theme-ui';
import { ShouldRender } from 'components/ShouldRender';
import { MultipleInvestigatorNotificationUpdateDto } from 'common/api';
import { BoxLoader } from 'components/loaders';
import { formData } from './AccountNotification';

interface Props {
  notifications: MultipleInvestigatorNotificationUpdateDto;
  setNotifications: (value: MultipleInvestigatorNotificationUpdateDto) => void;
  formDataList: Array<formData>;
  isFetching: boolean;
  setIsSaveEnabled: (value: boolean) => void;
}

export const NewSiteAppointments: React.FC<Props> = ({
  formDataList,
  isFetching,
  notifications,
  setNotifications,
  setIsSaveEnabled,
}: Props) => {
  const [isShowStudies, setIsShowStudies] = useState(true);

  const isPatientEmailChecked = (value: any): boolean => {
    return Boolean(
      notifications.investigatorNotificationUpdateDto.find(
        (investigator) => investigator.id === value
      )?.patientAppointmentEmail
    );
  };

  const isAllChecked = (): boolean => {
    return Boolean(
      !notifications.investigatorNotificationUpdateDto.find(
        (investigator) => !investigator.patientAppointmentEmail
      )
    );
  };

  const isPartiallyChecked = !isAllChecked()
    ? Boolean(
        notifications.investigatorNotificationUpdateDto.filter(
          (investigator) => investigator.patientAppointmentEmail
        ).length
      )
    : false;

  return (
    <AccountPageLayout>
      {isFetching ? (
        <BoxLoader />
      ) : (
        <Flex gap={3} sx={{ flexDirection: 'column' }}>
          <Grid
            sx={{
              gridTemplateColumns: '5% 80% 15%',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <LabeledCheckbox
              value="allNotification"
              name="allNotification"
              className="checkbox"
              checked={isAllChecked()}
              indeterminate={isPartiallyChecked ? 'true' : undefined}
              label=""
              onChange={(e) => {
                const updateData =
                  notifications.investigatorNotificationUpdateDto.map(
                    (investigator) => {
                      return {
                        ...investigator,
                        patientAppointmentEmail: e.target.checked,
                      };
                    }
                  );
                setNotifications({
                  investigatorNotificationUpdateDto: updateData,
                });
                setIsSaveEnabled(false);
              }}
              data-test-id="all-notifications-checkbox"
            />
            <Text
              variant="h4"
              sx={{
                color: !isAllChecked() && 'text2',
              }}
            >
              <FormattedMessage
                id="is.account_notification.new_site_appointment"
                defaultMessage="New Site Appointment"
              />
            </Text>

            <Button
              sx={{
                marginLeft: 'auto',
              }}
              variant="tertiary"
              size="extraLarge"
              onClick={() => setIsShowStudies(!isShowStudies)}
              icon={<ArrowIcon direction={isShowStudies ? 'top' : 'bottom'} />}
              data-test-id="site-employee-back-button"
            />
            <Box />
            <Text
              variant="p"
              sx={{
                color: !isAllChecked() && 'text2',
              }}
            >
              <FormattedMessage
                id="is.account_notification.new_site_appointment_paragraph"
                defaultMessage="E-mail send to the patient on behalf of 
                site with appointment details and meeting follow up reminder"
              />
            </Text>
          </Grid>

          <ShouldRender when={isShowStudies}>
            <Flex gap={3} sx={{ flexDirection: 'column' }}>
              {formDataList.map((formData) => {
                return (
                  <Box
                    sx={{
                      margin: '5px',
                    }}
                    key={formData.studyName}
                  >
                    <Grid sx={{ gridTemplateColumns: '5% 75% 20%' }}>
                      <Box />
                      <Box
                        sx={{
                          marginBottom: '10px',
                        }}
                      >
                        <Text variant="pBold">{formData.studyName}</Text>
                      </Box>
                      <Box />
                    </Grid>
                    <Grid sx={{ gridTemplateColumns: '5% 75% 20%' }}>
                      <Box />
                      <Box />
                      <Flex gap={2} sx={{ justifyContent: 'center' }} />

                      {formData.value?.map((investigator) => (
                        <React.Fragment key={investigator.id}>
                          <Box />

                          <Text
                            variant="p"
                            sx={{
                              maxWidth: '400px',
                              color:
                                !isPatientEmailChecked(investigator.id) &&
                                'text2',
                            }}
                          >
                            {investigator.studyName} (
                            {investigator.studyLanguage})
                          </Text>
                          <Flex gap={4} sx={{ justifyContent: 'center' }}>
                            <Checkbox
                              value={investigator.id}
                              name="instantNotification"
                              className="checkbox"
                              checked={isPatientEmailChecked(investigator.id)}
                              onChange={(e) => {
                                const update =
                                  notifications.investigatorNotificationUpdateDto.map(
                                    (investigator) => {
                                      if (
                                        investigator.id.toString() ===
                                        e.target.value
                                      ) {
                                        return {
                                          ...investigator,
                                          patientAppointmentEmail:
                                            e.target.checked,
                                        };
                                      }
                                      return { ...investigator };
                                    }
                                  );
                                setNotifications({
                                  investigatorNotificationUpdateDto: update,
                                });
                                setIsSaveEnabled(false);
                              }}
                              data-test-id="instant-notification-checkbox"
                            />
                          </Flex>
                        </React.Fragment>
                      ))}
                    </Grid>
                  </Box>
                );
              })}
            </Flex>
          </ShouldRender>
        </Flex>
      )}
    </AccountPageLayout>
  );
};
