import { useState } from 'react';
import { useIntl } from 'react-intl';
import { auth0 } from 'global/auth0';
import {
  setAccessToken,
  setRefreshToken,
  removeRefreshToken,
} from 'utils/auth';
import { useRedirect } from 'utils/routing';
import { Routing } from 'global/routing';
import { Services } from 'services';
import { Store } from 'store';
import { ErrorType } from 'utils/http';
import { Service } from 'common/api';
import { captureEventV2 } from 'utils/events/captureEvent';
import { Events } from '@clariness/tracking-event-runtime';
import { LoginResponse, ErrorMessage } from './types';
import { handleLoginError } from './handleLoginError';

const UNAUTHORIZED = 'unauthorized';
const PLEASE_CHANGE_YOUR_PASSSWORD = 'please change your password';

export function useLogin() {
  const [error, setError] = useState<ErrorMessage | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { user } = Store.useUser();
  const { redirect } = useRedirect();

  const intl = useIntl();

  const { showNotification } = Store.useNotifications();

  const login = (email: string, password: string) => {
    setIsLoading(true);
    setError(null);

    auth0.oauthToken(
      {
        realm: 'Username-Password-Authentication',
        username: email.toLowerCase(),
        password,
        grantType: 'http://auth0.com/oauth/grant-type/password-realm',
        scope: 'offline_access openid email profile',
      },
      async (error, data: LoginResponse) => {
        setIsLoading(false);

        if (error) {
          if (
            error.code === UNAUTHORIZED &&
            error.description === PLEASE_CHANGE_YOUR_PASSSWORD
          ) {
            const { href } = window.location;
            const isProduction = href.includes('investigators.clinlife.de');
            const redirectRoute = `${Routing.FORGOT_PASSWORD.getPath()}?expired=true`;

            if (!isProduction) {
              const { siteEmployeeExists } =
                await Service.siteEmployeeControllerResetPasswordCheck(email);

              if (siteEmployeeExists) {
                redirect(redirectRoute);
                return;
              }

              error.description = 'Wrong email or password.';
            } else {
              redirect(redirectRoute);
              return;
            }
          }

          const errorMessage = handleLoginError(error, intl);
          setError(errorMessage);
          showNotification({
            variant: 'error',
            description: errorMessage.description,
          });
        }

        if (data) {
          setAccessToken(data.accessToken);
          setRefreshToken(data.refreshToken);
          setIsSuccess(true);

          Service.siteEmployeeControllerUpdateCurrentUserLoginInfo()
            .then((user) => {
              captureEventV2(
                Events.InvestigatorService.InvestigatorLoggedIn({
                  InvestigatorID: user?.id ?? '',
                })
              );
            })
            .catch((error) => {
              console.warn('Error during login info update', error);
            });
        }
      }
    );
  };

  if (user) {
    redirect(Routing.HOME.getPath());
  }

  const siteEmployeeService = Services.SiteEmployees.useGetCurrentSiteEmployee({
    enabled: isSuccess,
    onSuccess: () => redirect(Routing.HOME.getPath()),
    onError: (error) => {
      if ((error as ErrorType).statusCode === 403) {
        showNotification({
          variant: 'error',
          description: intl.formatMessage({
            id: 'is.login.no_permissions',
            defaultMessage: "You don't have the right permissions to log in",
          }),
        });
      }
      setAccessToken(null);
      removeRefreshToken();
      setIsSuccess(false);
    },
  });

  return {
    login,
    isLoading: isLoading || siteEmployeeService.isLoading,
    error,
  };
}
