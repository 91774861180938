/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { InvestigatorDto } from './InvestigatorDto';
import type { LanguageDto } from './LanguageDto';
import type { ProtocolDto } from './ProtocolDto';
import type { SiteInfoDto } from './SiteInfoDto';
import type { StudyDto } from './StudyDto';

export type StudySiteDto = {
    id?: number;
    site: SiteInfoDto;
    study: StudyDto;
    distance?: number;
    status: StudySiteDto.status;
    possibleStatuses?: Array<'SETUP' | 'CANCELED' | 'ACTIVE' | 'INACTIVE' | 'PAUSED' | 'CLOSEOUT' | 'COMPLETED' | 'REMOVED'>;
    /**
     * Optimistic concurrency check
     */
    ocVersion?: number;
    protocol?: ProtocolDto;
    siteCode?: string;
    warmTransferAllowed: boolean;
    contactDetailsSharingAllowed: boolean;
    language?: LanguageDto;
    optIn?: boolean;
    goContact?: boolean;
    investigators?: Array<InvestigatorDto>;
    siteDeactivationReason?: StudySiteDto.siteDeactivationReason;
    capacity?: number;
}

export namespace StudySiteDto {

    export enum status {
        SETUP = 'SETUP',
        CANCELED = 'CANCELED',
        ACTIVE = 'ACTIVE',
        INACTIVE = 'INACTIVE',
        PAUSED = 'PAUSED',
        CLOSEOUT = 'CLOSEOUT',
        COMPLETED = 'COMPLETED',
        REMOVED = 'REMOVED',
    }

    export enum siteDeactivationReason {
        PATIENT_BACKLOG = 'PATIENT_BACKLOG',
        UNABLE_TO_REACH = 'UNABLE_TO_REACH',
        CAPACITY_REACHED = 'CAPACITY_REACHED',
        ON_SITE_REQUEST = 'ON_SITE_REQUEST',
        SPONSOR_REQUEST = 'SPONSOR_REQUEST',
    }


}
